<template>
  <div id="nav" class="flex items-center px-12 py-2 bg-indigo-100">
    <div class="flex items-center font-bold text-lg">
      <img
        src="https://img.icons8.com/cute-clipart/64/000000/partly-cloudy-day.png"
      />
      Vue Weather info
    </div>
    <div class="links ml-auto space-x-6 font-bold">
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "nav",
};
</script>

<style>
</style>