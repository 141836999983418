<template>
  <div class="about my-4 mx-12 space-y-5">
    <h1 class="text-center text-2xl font-semibold">About Vue Weather Info</h1>
    <p class="about-text">
      This is a vue demo website (you will see more info in this page soon...)
    </p>
  </div>
</template>

<script>
export default {
  name: "about",
};
</script>

<style>
.about-text {
  text-align: left;
}
</style>
