<template>
  <Navbarv />
  <router-view :url="url" :apikey="apikey" />
  <Footerv />
</template>

<script>
import Navbarv from "./components/Navbar.vue";
import Footerv from "./components/Footerv.vue";
import "./css/app.css";
import "./css/index.css";

export default {
  name: "App",
  components: {
    Navbarv,
    Footerv,
  },
  data() {
    return {
      url: "https://api.openweathermap.org/data/2.5/weather?q=",
      apikey: "&APPID=7c8a3f66b6e02bb59b207feb023b4010",
    };
  },
};
</script>

<style>
</style>
