<template>
  <div v-if="weatherdata === null">loadng...</div>
  <div v-else-if="weatherdata === 'error'">Could not able to featch data</div>
  <div v-else>
    <div class="content mb-4 flex justify-around">
      <div>
        <img src="https://img.icons8.com/color/48/000000/thermometer.png" />
        {{ weatherdata.temp }}°
      </div>
      <div>
        <img src="https://img.icons8.com/color/48/000000/wind.png" />
        {{ weatherdata.wind.speed }}
      </div>
      <div>
        <img
          src="https://img.icons8.com/color/48/000000/atmospheric-pressure.png"
        />
        {{ weatherdata.pressure }}
      </div>
    </div>
    <div class="content flex justify-around">
      <div>
        <p>Temprature : {{ weatherdata.temp }}°</p>
        <p>Temprature (Min) : {{ weatherdata.temp_min }}°</p>
        <p>Temprature (Max) : {{ weatherdata.temp_max }}°</p>
        <p>Feels Like : {{ weatherdata.temp_max }}°</p>
      </div>
      <div>
        <p>Wind Speed : {{ weatherdata.wind.speed }}</p>
        <p>Wind Direction : {{ weatherdata.wind.deg }}°</p>
      </div>
      <div>
        <p>pressure : {{ weatherdata.pressure }}</p>
        <p>humidity : {{ weatherdata.humidity }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "city",

  props: ["url", "city", "apikey"],

  data() {
    return {
      cityname: null,
      weatherdata: null,
      controller: null,
      signal: null,
    };
  },

  methods: {
    getdata() {
      fetch(this.link, { signal: this.signal })
        .then((response) => response.json())
        .then((data) => {
          this.weatherdata = {
            temp: parseFloat(data.main.temp - 273.15).toPrecision(4),
            feels_like: parseFloat(data.main.feels_like - 273.15).toPrecision(
              4
            ),
            temp_min: parseFloat(data.main.temp_min - 273.15).toPrecision(4),
            temp_max: parseFloat(data.main.temp_max - 273.15).toPrecision(4),
            pressure: data.main.pressure,
            humidity: data.main.humidity + "%",
            wind: data.wind,
          };
        })
        .catch((err) => {
          this.weatherdata = "error";
        });
    },
  },

  computed: {
    link() {
      return this.url + "" + this.cityname + "" + this.apikey;
    },
  },

  mounted() {
    this.cityname = this.city;

    this.controller = new AbortController();
    this.signal = this.controller.signal;

    if (this.city === "Your City") {
      let locationapi =
        "https://api.ipdata.co/?api-key=3ae7eb6af22ad9d3a2c3e54a6b1530a04c55fb6c6c8f2741c68f7552";
      fetch(locationapi, { signal: this.signal })
        .then((response) => response.json())
        .then((data) => {
          this.cityname = data.city;
          this.getdata();
        })
        .catch((err) => {
          this.weatherdata = "error";
          console.log(err);
        });
    } else {
      this.getdata();
    }
  },

  unmounted() {
    if (this.controller) {
      this.controller.abort();
    }
  },
};
</script>

<style>
</style>