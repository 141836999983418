<template>
  <footer>
    <p class="text-center bg-indigo-100 py-4">copyright 2021 Rahul Gupta</p>
    <p class="text-center text-xs">
      Note: This data may be incorrect at times, as the information is linked from a free weather API.
    </p>
  </footer>
</template>

<script>
export default {
  name: "footer",
};
</script>

<style>
</style>