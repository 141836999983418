<template>
  <div class="home mt-4 mx-12">
    <div class="locations flex justify-around">
      <span
        :key="onecity"
        v-for="onecity in cities"
        @click="change(onecity)"
        :class="{
          'bg-blue-500': cityname === onecity,
          'bg-green-500': cityname != onecity,
        }"
        class="text-center"
      >
        {{ onecity }}
      </span>
    </div>
    <City
      class="my-4"
      :key="cityname"
      :city="cityname"
      :url="url"
      :apikey="apikey"
    />
  </div>
</template>

<script>
import City from "../components/home/City.vue";

export default {
  name: "Home",
  props: ["url", "apikey"],
  components: {
    City,
  },
  data() {
    return {
      cityname: "Mumbai",
      cities: ["Mumbai", "New York", "London", "Your City"],
    };
  },
  mounted() {},
  methods: {
    change(name) {
      this.cityname = name;
    },
  },
};
</script>
